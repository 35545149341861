<template>
  <HomeLayout>
    <b-container
      class="d-flex flex-column align-items-start justify-content-start px-0"
    >
      <!-- table container row -->
      <b-row
        class="
          d-flex
          flex-row
          align-items-stretch
          justify-content-center
          w-100
          card-row
        "
        no-gutters
      >
        <b-overlay
          rounded="sm"
          no-wrap
          variant="light"
          :opacity="0.8"
          blur="2px"
        ></b-overlay>
        <b-card no-body class="d-flex flex-column w-100 card-scroll">
          <b-row
            class="
              d-flex
              flex-row
              align-items-start
              justify-content-start
              px-3
              py-0
              card-body-container
            "
            no-gutters
          >
            <!-- left column -->
            <b-col class="d-flex flex-column px-0 px-md-2 py-3 left-column">
              <!-- user details section -->
              <div class="user-details-section">
                <h6 class="inner-heading py-3">Donor Details</h6>
                <!-- user name -->
                <div class="inner-row-grid py-3 w-100">
                  <span
                    class="text-left text-prime-gray font-secondary col-align"
                  >
                    Name :
                  </span>
                  <span class="text-dark font-secondary txt-align">
                    {{
                      userDetails && userDetails.name ? userDetails.name : "N/A"
                    }}
                  </span>
                </div>
                <!-- user email -->
                <div class="inner-row-grid py-3 w-100">
                  <span
                    class="text-left text-prime-gray font-secondary col-align"
                  >
                    Email :
                  </span>
                  <b-row
                    class="
                      d-flex
                      flex-row
                      align-items-center
                      justify-content-start
                    "
                    no-gutters
                  >
                    <span class="text-left text-dark font-secondary txt-align">
                      {{
                        userDetails && userDetails.email
                          ? userDetails.email
                          : "N/A"
                      }}
                    </span>
                  </b-row>
                </div>
                <!-- user mobile number -->
                <div class="inner-row-grid py-3 w-100">
                  <span
                    class="text-left text-prime-gray font-secondary col-align"
                  >
                    Mobile :
                  </span>
                  <span class="text-left text-dark font-secondary txt-align">
                    {{
                      userDetails && userDetails.mobile
                        ? userDetails.mobile
                        : "N/A"
                    }}
                  </span>
                </div>
                <!-- Employer -->
                <div class="inner-row-grid py-3 w-100">
                  <span
                    class="text-left text-prime-gray font-secondary col-align"
                  >
                    Employer :
                  </span>
                  <span class="text-left text-dark font-secondary txt-align">
                    {{
                      userDetails && userDetails.employer
                        ? userDetails.employer
                        : "N/A"
                    }}
                  </span>
                </div>
                <!--position of epmloyment -->
                <div class="inner-row-grid py-3 w-100">
                  <span
                    class="text-left text-prime-gray font-secondary col-align"
                  >
                    Position of Employment :
                  </span>
                  <span class="text-left text-dark font-secondary txt-align">
                    {{
                      userDetails && userDetails.position
                        ? userDetails.position
                        : "N/A"
                    }}
                  </span>
                </div>
                <!-- address -->
                <div class="inner-row-grid py-3 w-100">
                  <span
                    class="text-left text-prime-gray font-secondary col-align"
                  >
                    Residential/Commercial Address :
                  </span>
                  <span class="text-left text-dark font-secondary txt-align">
                    {{
                      userDetails && userDetails.address
                        ? userDetails.address
                        : "N/A"
                    }}
                  </span>
                </div>
                <!-- city -->
                <div class="inner-row-grid py-3 w-100">
                  <span
                    class="text-left text-prime-gray font-secondary col-align"
                  >
                    City :
                  </span>
                  <span class="text-left text-dark font-secondary txt-align">
                    {{
                      userDetails && userDetails.city ? userDetails.city : "N/A"
                    }}
                  </span>
                </div>
                <!-- state -->
                <div class="inner-row-grid py-3 w-100">
                  <span
                    class="text-left text-prime-gray font-secondary col-align"
                  >
                    State :
                  </span>
                  <span class="text-left text-dark font-secondary txt-align">
                    {{
                      userDetails && userDetails.state
                        ? userDetails.state
                        : "N/A"
                    }}
                  </span>
                </div>
                <!-- user joined date -->
                <div class="inner-row-grid py-3 w-100">
                  <span
                    class="text-left text-prime-gray font-secondary col-align"
                  >
                    Joined Date :
                  </span>
                  <span class="text-left text-dark font-secondary txt-align">
                    {{
                      userDetails && userDetails.joinedDate
                        ? userDetails.joinedDate
                        : "N/A"
                    }}
                  </span>
                </div>
                <!-- user joined date -->
                <div class="inner-row-grid py-3 w-100">
                  <span
                    class="text-left text-prime-gray font-secondary col-align"
                  >
                    Donation :
                  </span>
                  <span class="text-left text-dark font-secondary txt-align">
                    {{
                      userDetails && userDetails.donation
                        ? getFormattedNumbers(userDetails.donation)
                        : "N/A"
                    }}
                  </span>
                </div>
              </div>
            </b-col>
            <!-- column divider -->
            <div class="column-divider align-self-center" />
            <!-- right column -->
            <b-col class="px-0 px-md-2 py-3 right-column">
              <!-- right column heading -->
              <h6 class="inner-heading py-3">Donor’s Generated Video</h6>
              <!-- video-container-row -->
              <b-row
                class="
                  d-flex
                  flex-row
                  align-items-start
                  justify-content-start
                  position-relative
                  video-container-row
                "
                no-gutters
              >
                <img
                  :src="
                    targetVideo && targetVideo.videoPreviewImgURL
                      ? targetVideo.videoPreviewImgURL
                      : ''
                  "
                  class="video-preview-image"
                />
                <!-- preview video button -->
                <b-button
                  v-b-tooltip.hover
                  title="Preview Video"
                  size="sm"
                  class="
                    d-flex
                    flex-row
                    align-items-center
                    justify-content-center
                    video-preview-button
                  "
                  pill
                  @click="openPreviewVideoModal(targetVideo, $event.target)"
                >
                  <font-awesome-icon
                    icon="play"
                    aria-label="Preview Video"
                    class="play-icon"
                  ></font-awesome-icon>
                </b-button>
              </b-row>
              <!-- video title -->
              <b-row
                class="
                  d-flex
                  flex-row
                  align-items-center
                  justify-content-center
                  p-2
                "
              >
                <h6 class="video-title my-2">
                  {{
                    targetVideo && targetVideo.videoTitle
                      ? targetVideo.videoTitle
                      : ""
                  }}
                </h6>
              </b-row>
              <!-- video link action -->
              <b-row
                class="video-actions-row py-4"
                v-if="targetVideo && targetVideo.videoURL"
              >
                <b-col cols="12">
                  <h6 class="inner-heading py-2">Video Preview Link</h6>
                  <!-- video preview link -->
                  <FormInputGroup
                    id="video-preview-link-input"
                    class="video-preview-form-group"
                    v-model="getVideoPreviewLink"
                    isAppend
                    type="text"
                  >
                    <template v-slot:append>
                      <b-button
                        class="px-2 px-md-3 py-0 bg-light append-button"
                        variant="transparent"
                        @click="copyLink('video-preview-link-input')"
                      >
                        <span>Copy Link</span>
                        <font-awesome-icon
                          class="text-prime-dark fa-lg ml-1"
                          icon="link"
                        ></font-awesome-icon>
                      </b-button>
                    </template>
                  </FormInputGroup>
                </b-col>
                <b-col cols="12">
                  <h6 class="inner-heading py-2">Video Download Link</h6>
                  <!-- video download link -->
                  <FormInputGroup
                    id="video-download-link-input"
                    class="video-download-form-group"
                    v-model="getVideoDownloadLink"
                    isAppend
                    type="text"
                  >
                    <template v-slot:append>
                      <b-button
                        class="px-2 px-md-3 py-0 bg-light append-button"
                        variant="transparent"
                        @click="copyLink('video-download-link-input')"
                      >
                        <span>Copy Link</span>
                        <font-awesome-icon
                          class="text-prime-dark fa-lg ml-1"
                          icon="link"
                        ></font-awesome-icon>
                      </b-button>
                    </template>
                  </FormInputGroup>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-row>
      <PreviewModal
        :isShow="isShowPreviewModal"
        @onClose="onClosePreviewVideoModal"
        :itemContent="previewVideoItem"
      />
    </b-container>
  </HomeLayout>
</template>

<script>
// @ is an alias to /src
import HomeLayout from "@/layout/HomeLayout";
import FormInputGroup from "@/components/Form/FormInputGroup";
import PreviewModal from "@/components/Videos/Modals/PreviewModal";

// services

import { GetSingleDonor } from "@/services/donor.service";

import {formatPrice } from "@/util/formatPrice.js";


export default {
  name: "DonorsProfile",
  components: {
    HomeLayout,
    FormInputGroup,
    PreviewModal,
  },
  data() {
    return {
      getVideoDownloadLink:null,
      getVideoPreviewLink:null,
      isAPILoading: true,
      modalShow: false,
      previewVideoItem: null,
      isShowPreviewModal: false,
      userDetails: null,
      targetVideo: null,
      videoTitle: null,
      videoLink: null,
      video: null,
      webURL: process.env.VUE_APP_WEB_URL,
    };
  },
  async mounted() {
    await this.initFn(this.$route.params.userId);
  },
  computed: {},
  methods: {
    async initFn(userId) {
      try {
        this.isAPILoading = true;
        let { data } = await GetSingleDonor(userId);
        if (data.code == 200 || data.message == "success") {
          this.getVideoDownloadLink = data.content.resultVideoDownloadLink;
          this.getVideoPreviewLink = data.content.resultVideoPreviewLink;
          let user = data.content;

          // if user id is valid object id but does not exist
          if (!user) return this.handleUserFetchError();

          this.userDetails = {
            name: user.firstName + " " + user.lastName,
            email: user.email,
            mobile: user.phone,
            employer: user.employer,
            position: user.occupation,
            address: user.address,
            city: user.city,
            state: user.state,
            joinedDate: user.created_at.split("T")[0],
            donation: user.contributionAmount,
            
          };
          this.targetVideo = {
            videoTitle: user.targetVideoTitle,
            videoPreviewImgURL: user.targetVideoPreviewImage,
            videoURL: user.targetVideo,
          };
        } else
          this.handleUserFetchError(data.status, data.details, [data.others]);
      } catch (error) {
        console.log("ERROR DP01");
      } finally {
        this.isAPILoading = false;
      }
    },
    handleUserFetchError(
      title = "User Details",
      content = "Requested user not found",
      list = ["Unable to find User by ID"]
    ) {
      let payloadNotify = {
        isToast: true,
        title,
        content,
        list,
        variant: "danger",
      };
      this.$store.dispatch("notification/setNotify", payloadNotify);
      setTimeout(() => this.$router.back(), 2000);
    },
    copyLink(elementId) {
      try {
        let copyInputElement = document.getElementById(elementId);
        copyInputElement.setAttribute("type", "text");
        copyInputElement.select();
        document.execCommand("copy");
        let payloadNotify = {
          isToast: true,
          title: "Video Preview Link",
          content: "Link was copied successfully.",
          variant: "success",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      } catch (err) {
        let payloadNotify = {
          isToast: true,
          title: "Video Preview Link",
          content: "Unabe to copy vidoe preview link to the clipboard.",
          list: [err.message],
          variant: "danger",
        };
        this.$store.dispatch("notification/setNotify", payloadNotify);
      }
    },
    // preview modal functions
    openPreviewVideoModal(item, button) {
      this.isShowPreviewModal = true;
      this.previewVideoItem = item;
      this.$root.$emit("bv::show::modal", "preview-video-row-modal", button);
    },
    onClosePreviewVideoModal() {
      this.isShowPreviewModal = false;
    },
       getFormattedNumbers(text) {
      if (!text) return "N/A";
      return formatPrice(text);
    },
  },
};
</script>
<style scoped>
.col-align {
  width: 180px;
}
.txt-align {
  margin-left: 70px;
}
/* container card */
.card-row {
  height: 100%;
}
.card-row .card-scroll {
  border: 1px solid rgba(135, 147, 163, 0.25);
  box-sizing: border-box;
  background-color: var(--white);
  border-radius: 16px;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
}
.card-row .card-scroll::-webkit-scrollbar {
  height: 0.5rem;
  scrollbar-width: thin;
}
.card-row .inner-heading {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
}
/* card body container */
.card-body-container {
  display: grid;
  grid-template-columns: 48% 4% 48%;
  height: 100%;
}
/* card body left column */
.card-body-container .left-column {
  display: grid;
  grid-gap: 3rem;
  margin-right: 1rem;
}
.card-body-container .left-column .user-details-section .inner-row-grid {
  display: grid;
  grid-template-columns: 25% 70%;
  grid-gap: 5%;
}
/* card body right column */
.card-body-container .right-column {
  margin-left: 1rem;
}
.card-body-container .right-column .video-container-row {
  overflow: hidden;
}
/* video preview image */
.video-container-row .video-preview-image {
  width: 100%;
  height: 280px;
  border-radius: 8px;
  outline: none;
  border: none;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: thin;
  object-fit: cover;
  filter: brightness(0.6);
}
/* video preview button */
.video-container-row .video-preview-button {
  position: absolute;
  width: 50px;
  height: 50px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 38px;
  box-shadow: 0px 2px 8px var(--shadow-soft);
  border: 2px solid var(--white);
  padding: 0 0 0 3px;
  color: var(--white);
  background-color: transparent;
  transition: color, background-color 200ms ease-in;
}
.video-container-row .video-preview-button .play-icon {
  width: 24px;
  height: 24px;
}
/* video preview button (hover state) */
.video-container-row .video-preview-button:hover {
  color: var(--dark);
  background-color: var(--white);
}
/* video actions row */
.video-actions-row .video-preview-form-group {
  height: 50px;
}
.video-actions-row .video-preview-form-group .append-button {
  background-color: rgba(236, 239, 244, 1);
  border-radius: 0px 8px 8px 0px;
  width: auto;
  max-width: 160px;
  height: 100%;
  border: 1px solid rgba(135, 147, 163, 0.25);
  box-sizing: border-box;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}
.card-body-container .column-divider {
  width: 0;
  height: 100%;
  border-right: 1px solid var(--light);
}

/* .card-body-container .right-column .inner-row-grid {
  display: grid;
  grid-gap: 1.5rem;
  background-color:red;
} */
.card-body-container .right-column .right-column-card {
  background: #333333;
  opacity: 0.6;
  border-radius: 13px;
  width: 520px;
  height: 292.5px;
}

.card-body-container .right-column {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 18px;
}
#video-preview-link-input ~ div {
  max-height: 50px;
}
/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .card-body-container {
    grid-template-columns: 100%;
    grid-gap: 0.3rem;
  }
  .card-body-container .left-column {
    margin-right: 0;
  }
  .card-body-container .right-column {
    margin-left: 0;
  }
  .card-body-container .column-divider {
    width: 100%;
    height: 0;
    border-right: none;
    border-top: 1px solid var(--light);
  }
  .card-row .card-scroll {
    padding: 1.5rem 0;
  }
}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .card-body-container .left-column .user-details-section .inner-row-grid {
    grid-template-columns: 100%;
    grid-gap: 0.3rem;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: thin;
  }
}
</style>
